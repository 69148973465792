import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { leftMenuItems } from "data/menuItems";

const LeftMenu = ({className = "rounde-lg shadow-lg p-6", onItemClick}) => {

    const { t } = useTranslation();
    const location = useLocation();
    const { pathname } = location;

    const pages = pathname.split('/');

    let currentPageStructure = '';

    let pageDepth = 2;

    for (let i = pageDepth; i < pages.length; i++) {
        if (i === pages.length - 1) {
            currentPageStructure = currentPageStructure + pages[i];
        } else {
            currentPageStructure = currentPageStructure + pages[i] + '/';
        }
    }

    return (
        <div className={`flex-col min-w-[272px] ${className}`}>
            <ul>
                {leftMenuItems.map((item, i) => {

                    let isCurrentPage = currentPageStructure === item.to;
                    let shouldExpand = item.to === currentPageStructure?.split('/').shift();

                    return (
                        <li key={i} className="flex flex-col list-none mb-2 w-full">
                            <div className={`flex items-center border-b-2 pb-2 ${isCurrentPage ? 'border-airpark-green' : 'border-white'}`}>
                                {item.icon}
                                <NavLink
                                    key={i}
                                    onClick={onItemClick}
                                    className="pl-2 font-medium"
                                    to={item.to}>
                                    {item.title ?? t(item.translationKey)}
                                </NavLink>
                            </div>
                            {shouldExpand && item.subItems &&
                                <div className="flex flex-col mt-1 mb-1">
                                    {item.subItems.map((subItem, i) => {
                                        return (
                                            <NavLink
                                                key={i} to={subItem.to}
                                                onClick={onItemClick}
                                                className={`pl-8 border-b-2 pb-1 ${currentPageStructure === subItem.to ? 'border-airpark-green' : 'border-white'}`}>
                                                {item.title ?? t(subItem.translationKey)}
                                            </NavLink>)
                                    })}
                                </div>
                            }
                        </li>
                    )
                })}
            </ul>
        </div>
    );
}

export default LeftMenu;