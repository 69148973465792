import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ReactComponent as OrganizationsIcon } from 'assets/icons/ico_organizations.svg';
import { ReactComponent as OrganizationsAddIcon } from 'assets/icons/ico_organizations_add.svg';
import { ReactComponent as UserIcon } from 'assets/icons/ico_user.svg';
import { ReactComponent as AirParkSmallIcon } from 'assets/icons/ico_airpark_p_green.svg';

import Logo from "components/common/logo";
import ButtonText from "components/common/buttonText";
import { useFrontDesk } from "context/frontDesk";
import { useAuth0 } from "@auth0/auth0-react";

const DesktopMenu = ({ organization }) => {

    const { user, organizations } = useFrontDesk();
    const { logout } = useAuth0();
    const navigate = useNavigate();
    const [showOrganizationMenu, setShowOrganizationMenu] = useState();
    const [showUserMenu, setShowUserMenu] = useState();
    const { t } = useTranslation();
    const [showOrganizationMenuClassName, setShowOrganizationMenuClassName] = useState('flex text-left flex-col absolute right-8 top-8 p-6 min-w-[348px] rounded-3xl bg-white shadow z-30 transition-all ease-in-out duration-100 scale-75 opacity-50');
    const [showUserMenuClassName, setShowUserMenuClassName] = useState('flex text-left flex-col absolute right-8 top-8 p-6 min-w-[348px] rounded-3xl bg-white shadow z-30 transition-all ease-in-out duration-100 scale-75 opacity-50');

    const toggleOrganizationMenu = (e) => {
        e.preventDefault();
        setShowUserMenu(false);
        setShowOrganizationMenu(!showOrganizationMenu);
    }

    const toggleUserMenu = (e) => {
        e.preventDefault();
        setShowOrganizationMenu(false);
        setShowUserMenu(!showUserMenu);
    }

    const navigateToOrganization = ((e, organizationExtId) => {
        e.preventDefault();
        setShowOrganizationMenu(false);
        navigate(`/${organizationExtId.toLowerCase()}`);
    });

    const handleLogoClick = (e) => {
        e.preventDefault();
        if (organization) {
            navigate(`/${organization.organizationExtId.toLowerCase()}`)
        }
    }

    useEffect(() => {
        if (showOrganizationMenu) {
            setShowOrganizationMenuClassName('flex text-left flex-col absolute right-8 top-8 p-6 min-w-[348px] rounded-3xl bg-white shadow z-30 transition-all ease-in-out duration-100 scale-100 opacity-100');
        } else {
            setShowOrganizationMenuClassName('flex text-left flex-col absolute right-8 top-8 p-6 min-w-[348px] rounded-3xl bg-white shadow z-30 transition-all ease-in-out duration-100 scale-75 opacity-50');
        }
    }, [showOrganizationMenu]);

    useEffect(() => {
        if (showUserMenu) {
            setShowUserMenuClassName('flex text-left flex-col absolute right-0 top-8 p-6 min-w-[348px] rounded-3xl bg-white shadow z-30 transition-all ease-in-out duration-100 scale-100 opacity-100');
        } else {
            setShowUserMenuClassName('flex text-left flex-col absolute right-0 top-8 p-6 min-w-[348px] rounded-3xl bg-white shadow z-30 transition-all ease-in-out duration-100 scale-75 opacity-50');
        }
    }, [showUserMenu]);

    return (
        <>
            {/* Overlay */}
            {(showOrganizationMenu || showUserMenu) &&
                <div onClick={(e) => { e.preventDefault(); setShowOrganizationMenu(false); setShowUserMenu(false); }} className="fixed inset-0 z-20" />
            }

            <nav className="hidden lg:flex bg-white shadow h-16 items-center justify-between pr-6 pl-6">
                <button onClick={(e) => { handleLogoClick(e) }} href="#">
                    <div className="hidden md:block">
                        <Logo />
                    </div>
                    <div className="md:hidden block">
                        <AirParkSmallIcon className="h-11 w-11" />
                    </div>
                </button>
                <div>
                    
                </div>
                <div className="md:flex items-center relative hidden">
                    <button onClick={(e) => { e.preventDefault(); toggleOrganizationMenu(e); }} className="flex items-center">
                        {organization &&
                            <span className="text-base font-medium truncate text-ellipsis max-w-xs">{organization.name}</span>
                        }
                        <OrganizationsIcon className="h-5 w-5 ml-4" />
                    </button>
                    {showOrganizationMenu &&
                        <div className={showOrganizationMenuClassName}>
                            <div className="flex items-center">
                                <OrganizationsAddIcon className="w-5 h-5 mr-2" />
                                <span className="font-medium text-base">{t('topBar.organizations')}</span>
                            </div>
                            <div className="flex flex-col">
                                {organizations && organizations.map((o, i) => {
                                    return (
                                        <button key={i} onClick={(e) => { navigateToOrganization(e, o.organizationExtId); }} className="flex items-center pointer-cursor">
                                            <div className="flex justify-center w-5 mr-2">
                                                {organization && organization.name === o.name &&
                                                    <div className="flex justify-center h-2 w-2 rounded bg-green-500" />
                                                }
                                            </div>
                                            <span className="truncate pt-1 max-w-[240px]">{o.name}</span>
                                        </button>)
                                })
                                }
                            </div>
                        </div>
                    }
                    <button onClick={(e) => { toggleUserMenu(e); }}>
                        <UserIcon className="h-5 w-5 ml-4" />
                    </button>
                    {showUserMenu &&
                        <div className={showUserMenuClassName}>
                            <div className="flex items-center">
                                <UserIcon className="w-5 h-5 mr-2" />
                                <span className="font-medium text-base">{user.firstName} {user.lastName}</span>
                            </div>
                            <ButtonText onClick={() => { logout({ returnTo: window.location.origin }); }} isSecondary={false} className="mt-4">
                                <span className="text-center w-full">{t('topBar.logout')}</span>
                            </ButtonText>
                        </div>
                    }
                </div>
            </nav>
        </>
    );
};

export default DesktopMenu;