import { useAuth0 } from '@auth0/auth0-react';
import ButtonText from 'components/common/buttonText';
import Logo from 'components/common/logo';
import Widget from 'components/common/widget';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const LoginPage = () => {

    const { t } = useTranslation();
    const { logout, loginWithRedirect } = useAuth0();
    const [isLoading, setIsLoading]  = useState(false);

    return (
        <div className="bg-cover bg-center h-screen flex items-center justify-center bg-login-page">
            <div className="absolute top-12">
                <Logo />
            </div>
            <div className="w-full max-w-md ">
                <div className="flex flex-col p-6 md:p-0">
                    <Widget>
                        <h1 className="text-2xl font-medium mb-4">{t('loginPage.title')}</h1>
                        <span className="font-base text-base mb-6">{t('loginPage.description')}</span>
                        <ButtonText 
                            isLoading={isLoading}
                            disabled={isLoading}
                            onClick={() => {
                            /* Clear any other session first */
                            setIsLoading(true);
                            logout();
                            loginWithRedirect();
                        }}>
                            {t('loginPage.login')}
                        </ButtonText>
                    </Widget>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;