import React, { useEffect, useState } from 'react';
import { ReactComponent as AirParkSmallIcon } from 'assets/icons/ico_airpark_p_green.svg';
import { ReactComponent as IconMenuClose } from 'assets/icons/ico_menu_close.svg';
import { ReactComponent as IconMenuOpen } from 'assets/icons/ico_menu_open.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ButtonText from 'components/common/buttonText';
import DropdownSelector from 'components/common/dropdownSelector/dropdownSelector';
import LeftMenu from 'components/common/leftMenu';
import { useFrontDesk } from "context/frontDesk";
import { useAuth0 } from "@auth0/auth0-react";

const MobileMenu = ({ organization }) => {
    const { organizations } = useFrontDesk();

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { logout } = useAuth0();

    const handleLogoClick = (e) => {
        e.preventDefault();
        if (organization) {
            navigate(`/${organization.organizationExtId.toLowerCase()}`)
        }
    }

    useEffect(() => {
        if (isMenuOpen) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    }, [isMenuOpen])

    return (
            <nav className="lg:hidden flex fixed inset-0 h-16 w-full items-center justify-between px-6 z-50 bg-white shadow">
                <div className="flex items-center gap-x-2 cursor-pointer" onClick={(e) => { handleLogoClick(e); }}>
                    <AirParkSmallIcon className="h-11" />
                    <span className="font-medium text-lg">AirPark</span>
                </div>
                <div className="flex gap-x-3">
                    <button onClick={(e) => { e.preventDefault(); setIsMenuOpen(true); }}>
                        <IconMenuOpen className="h-6 w-6" />
                    </button>
                </div>
                {isMenuOpen &&
                    <div className="fixed inset-0 z-50 bg-black opacity-70" onClick={(e) => { e.preventDefault(); setIsMenuOpen(false); }} />
                }
                <ul className={`flex flex-col gap-y-3 p-6 fixed bg-white max-w-[375px] w-full top-0 z-50 transition-all ease-in-out duration-300 h-screen ${isMenuOpen ? 'right-0' : '-right-[375px] '} overflow-y-auto`}>
                    <button className="flex h-11 w-11 mb-6 items-center justify-center -ml-3" onClick={(e) => { e.preventDefault(); setIsMenuOpen(false); }}>
                        <IconMenuClose className="h-6 w-6" />
                    </button>
                    <LeftMenu className={""} onItemClick={() => setIsMenuOpen(false)} />
                    <li>
                        <ButtonText onClick={() => { logout(); }} isSecondary={false} className="my-4">
                            <span className="text-center w-full">{t('topBar.logout')}</span>
                        </ButtonText>
                    </li>
                    {organization && organizations &&
                        <>
                            <DropdownSelector
                                options={organizations.map((organization) => { return { title: organization.name, value: organization.organizationExtId } })}
                                defaultValue={organization?.organizationExtId}
                                onChange={(value) => { navigate(`/${value.toLowerCase()}`); setIsMenuOpen(false) }} />
                        </>
                    }
                </ul>
            </nav>
    );
};

export default MobileMenu;